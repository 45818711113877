import React, { Fragment, useEffect, useState } from "react"
import { graphql } from "gatsby"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
//import 'react-tabs/style/react-tabs.css';
import styled from "styled-components"
import { colors } from "../consts/style"
import Seo from "../components/Seo"
import BtnPrimary from "../components/buttons/ButtonRounded"
import Boop from "../components/boop"
import {
  PageWrapper,
  PageInner,
  PageTitle,
  BgWrap,
  Spacer,
  Text,
  ArrowLeftLink,
  ArrowRightLink,
  ArrowLeftIcon,
  ArrowRightIcon,
} from "../components/Elements"
import Calendar from "react-calendar"
import { useIntl } from "react-intl"
import { isSameDay, isWithinInterval } from "date-fns"

import "../style/react-tabs.css"
import "../style/calendar.css"

export const horairesTarifsQuery = graphql`
  query horairesTarifsQuery($locale: String) {
    banner: file(relativePath: { eq: "bandeau_ressources.png" }) {
      childImageSharp {
        fluid(maxWidth: 1820) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    page: datoCmsHorairesTarifsPage(locale: { eq: $locale }) {
      titreHoraires
      horairesContenu
      titreTarifs

      pdfHorairesIndividuel {
        url
      }
      pdfHorairesGroupe {
        url
      }
      pdfHorairesFamille {
        url
      }
      pdfHorairesEtudiant {
        url
      }
      tarifsFamille
      tarifsGroupe
      tarifsIndividuel
      tarifsEtudiant
      tarifsContenu

      horairesOuverture {
        dateDebut
        dateFin
        
        classeCss
      }

      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`

const LegendeWrapper = styled.div`
  padding: 1rem;
  display: flex;
  gap: 0.5rem;
`

const LegendeItem = styled.div`
  padding: 1rem;
  display: flex;
  align-items: center;
  border: 1px solid ${colors.grey};
  flex-wrap: wrap;
`

const LegendeSquare = styled.div`
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
  display: block;
`

const ArrowLeftLinkNav = styled(ArrowLeftLink)`
  /*position:absolute;
  bottom:50%;
  left:1rem;*/
  z-index: 1;
  cursor: pointer;
  /*transform: translate3d(0, 50%, 0);*/
  &.swiper-button-disabled {
    opacity: 0;
  }
`

const ArrowRightLinkNav = styled(ArrowRightLink)`
  /*  position:absolute;
  bottom:50%;
  right:1rem;
  transform: translate3d(0, 50%, 0);*/
  z-index: 1;
  cursor: pointer;
  &.swiper-button-disabled {
    opacity: 0;
  }
`

/*Ajout des dates individuelles (attention au mois qui commence à 0 = Janvier) et de la classe css associée*/
/*
const datesToAddClassTo = [
  {date: new Date(2021, 3, 7), className:'open-1330-1830'},
  {date: new Date(2021, 4, 12), className:'open-1330-1830'},
  {date: new Date(2021, 4, 19), className:'open-1330-1830'},
  {date: new Date(2021, 4, 22), className:'open-10-19'},
  {date: new Date(2021, 4, 23), className:'open-10-19'},
  {date: new Date(2021, 4, 26), className:'open-1330-1830'},
  {date: new Date(2021, 4, 29), className:'open-10-19'},
  {date: new Date(2021, 4, 30), className:'open-10-19'},

  {date: new Date(2021, 5, 5), className:'open-10-19'},
  {date: new Date(2021, 5, 6), className:'open-10-19'},
  {date: new Date(2021, 5, 12), className:'open-10-19'},
  {date: new Date(2021, 5, 13), className:'open-10-19'},
  {date: new Date(2021, 5, 19), className:'open-10-19'},
  {date: new Date(2021, 5, 20), className:'open-10-19'},
  {date: new Date(2021, 5, 26), className:'open-10-19'},
  {date: new Date(2021, 5, 27), className:'open-10-19'},

  {date: new Date(2021, 8, 1), className:'open-1330-1830'},
  {date: new Date(2021, 8, 8), className:'open-1330-1830'},
  {date: new Date(2021, 8, 15), className:'open-1330-1830'},
  {date: new Date(2021, 8, 22), className:'open-1330-1830'},
  {date: new Date(2021, 8, 29), className:'open-1330-1830'},
 
  {date: new Date(2021, 8, 4), className:'open-10-19'},
  {date: new Date(2021, 8, 5), className:'open-10-19'},
  {date: new Date(2021, 8, 11), className:'open-10-19'},
  {date: new Date(2021, 8, 12), className:'open-10-19'},
  {date: new Date(2021, 8, 18), className:'open-10-19'},
  {date: new Date(2021, 8, 19), className:'open-10-19'},
  {date: new Date(2021, 8, 25), className:'open-10-19'},
  {date: new Date(2021, 8, 26), className:'open-10-19'},

  {date: new Date(2021, 9, 6), className:'open-1330-1830'},
  {date: new Date(2021, 9, 13), className:'open-1330-1830'},
  {date: new Date(2021, 9, 20), className:'open-1330-1830'},
  {date: new Date(2021, 9, 2), className:'open-11-1830'},
  {date: new Date(2021, 9, 3), className:'open-11-1830'},
  {date: new Date(2021, 9, 9), className:'open-11-1830'},
  {date: new Date(2021, 9, 10), className:'open-11-1830'},
  {date: new Date(2021, 9, 16), className:'open-11-1830'},
  {date: new Date(2021, 9, 17), className:'open-11-1830'},

];*/
/* Verifie si la date passée en paramètre est une date à laquelle on doit ajouter 
des horaires d'ouverture via une classe css, retournée par cette fonction */
/*
function openingHours({ date, view}) {
 
  // Add class to tiles in month view only
  if (view === 'month') {
    
    // Check if a date React-Calendar wants to check is on the list of individual dates 
    const result = datesToAddClassTo.find(dDate => isSameDay(dDate.date, date));
    if (result) {
      return result.className;
    }
  

     // TEST 2022
     if (
      isWithinInterval(date, { start: new Date(2022, 1, 10) , end: new Date(2022, 3, 30) })) {
      return 'open-10-19';
    }
     // FIN TEST 2022

    // du 10 au 30 avril
    if (
      isWithinInterval(date, { start: new Date(2021, 3, 10) , end: new Date(2021, 3, 30) })) {
      return 'open-10-19';
    }
    
    // du 1 au 9 mai
    if (
      isWithinInterval(date, { start: new Date(2021, 4, 1) , end: new Date(2021, 4, 9) })) {
      return 'open-10-19';
    }

    //13 au 16 mai
    if (
      isWithinInterval(date, { start: new Date(2021, 4, 13) , end: new Date(2021, 4, 16) })) {
      return 'open-10-19';
    }

     //1 au 4 juin,
     // 7 au 11 juin
     // 14 au 18 juin
     // 21 au 25 juin
     // 28 au 30 juin
     if (
      isWithinInterval(date, { start: new Date(2021, 5, 1) , end: new Date(2021, 5, 4) }) || 
      isWithinInterval(date, { start: new Date(2021, 5, 7) , end: new Date(2021, 5, 11) }) || 
      isWithinInterval(date, { start: new Date(2021, 5, 14) , end: new Date(2021, 5, 18) }) || 
      isWithinInterval(date, { start: new Date(2021, 5, 21) , end: new Date(2021, 5, 25) }) || 
      isWithinInterval(date, { start: new Date(2021, 5, 28) , end: new Date(2021, 5, 30) })   
      ) {
      return 'open-1330-1830';
    }
    // du 03/07 au 31/08 
    if (
      isWithinInterval(date, { start: new Date(2021, 6, 3) , end: new Date(2021, 7, 31) })) {
      return 'open-9-20';
    }
    // du 23/10 au 07/11 
    if (
      isWithinInterval(date, { start: new Date(2021, 9, 23) , end: new Date(2021, 10, 7) })) {
      return 'open-11-1830';
    }

  }
}
  */

/* Reinitialise l'heure de la date à 0*/
const formatDate = date => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear()

  if (month.length < 2) month = "0" + month
  if (day.length < 2) day = "0" + day

  return [year, month, day].join("-") + "T00:00:00Z"
}

const useWindowWidth = () => {
  const isBrowser = typeof window !== "undefined"
  const [width, setWidth] = useState(isBrowser ? window.innerWidth : 0)

  useEffect(() => {
    if (!isBrowser) return false

    const handleResize = () => setWidth(window.innerWidth)
    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  })

  return width
}

const HorairesTarifsPage = ({ data }) => {
  const {
    titreHoraires,
    horairesContenu,
    titreTarifs,
    horairesOuverture,
    pdfHorairesIndividuel,
    pdfHorairesFamille,
    pdfHorairesGroupe,
    pdfHorairesEtudiant,
    tarifsIndividuel,
    tarifsFamille,
    tarifsGroupe,
    tarifsEtudiant,
    tarifsContenu,
    seoMetaTags,
  } = data.page
  /*calendar logic*/
  const intl = useIntl()
  const windowWidth = useWindowWidth()
  /* On enlève les doublons au niveau de l'attribut plage horaire de l'objet periode */
  const tableauLegendesCalendar = horairesOuverture.filter(
    (periode, index, self) =>
      index === self.findIndex(t => t.classeCss === periode.classeCss)
  )

  return (
    <Fragment>
      <Seo meta={seoMetaTags} />
      <PageWrapper>
        {/* <Banner>
          <Img fluid={data.banner.childImageSharp.fluid} />
        </Banner>*/}
        <BgWrap
          curved
          color=" linear-gradient(180deg, rgba(232, 231, 231, 0) 36.96%, #F3F3F3 92.16%)"
        >
          <PageInner>
            <PageTitle dangerouslySetInnerHTML={{ __html: titreHoraires }} />
            <Text dangerouslySetInnerHTML={{ __html: horairesContenu }} />
            <Spacer />

            <Calendar
              showDoubleView={windowWidth <= 800 ? false : true}
              maxDate={new Date(2025, 11, 31)}
              minDate={new Date()}
              minDetail="month"
              // tileClassName = {openingHours}
              /* Verifie si la date passée en paramètre est une date à laquelle on doit ajouter 
              des horaires d'ouverture via une classe css, retournée par cette fonction. 
              On se base sur les periodes d'ouverture saisies dans datoCMS */
              tileClassName={({ date, view }) => {
                if (view === "month") {
                  return horairesOuverture.map(periode => {
                    return isWithinInterval(new Date(formatDate(date)), {
                      start: new Date(periode.dateDebut),
                      end: new Date(periode.dateFin),
                    })
                      ? periode.classeCss
                      : null
                  })
                }
              }}
              locale={intl.locale}
              nextLabel={
                <ArrowRightLinkNav>
                  <Boop scale={1.05} timing={200}>
                    <ArrowRightIcon title="Précédent" />
                  </Boop>
                </ArrowRightLinkNav>
              }
              prevLabel={
                <ArrowLeftLinkNav>
                  <Boop scale={1.05} timing={200}>
                    <ArrowLeftIcon title="Précédent" />
                  </Boop>
                </ArrowLeftLinkNav>
              }
            />

            <LegendeWrapper>
              {
                /* parcours du tableau des légendes des plages horaires uniques */
                tableauLegendesCalendar.map((periode, key) => {
                  return (
                    <LegendeItem key={key}>
                      <LegendeSquare className={periode.classeCss} />{" "}
                      {periode.classeCss.substr(5)}
                    </LegendeItem>
                  )
                })
              }
              {/* 
                <LegendeItem><LegendeSquare background="#0f0692"/> 13h30-18h30</LegendeItem>
                <LegendeItem><LegendeSquare background="#17884e"/> 9h-20h</LegendeItem>
                <LegendeItem><LegendeSquare background="#00adbc"/> 10h-19h</LegendeItem>
                <LegendeItem><LegendeSquare background="#810863"/> 10h-18h30</LegendeItem>
                <LegendeItem><LegendeSquare background="#8d8f15"/> 11h-18h30</LegendeItem>
                */}
            </LegendeWrapper>
            <Spacer />
          </PageInner>
        </BgWrap>
        <BgWrap
          id="traifs"
          curved
          color=" linear-gradient(180deg, rgba(232, 231, 231, 0) 36.96%, #F3F3F3 92.16%)"
        >
          <PageInner>
            <PageTitle dangerouslySetInnerHTML={{ __html: titreTarifs }} />
            <Tabs>
              <TabList>
                <Tab>Individuel</Tab>
                <Tab>Famille</Tab>
                <Tab>Groupe</Tab>
                <Tab>Etudiant</Tab>
              </TabList>

              <TabPanel>
                <div dangerouslySetInnerHTML={{ __html: tarifsIndividuel }} />
                { pdfHorairesIndividuel && 
                <BtnPrimary
                  external
                  as="a"
                  target="_blank"
                  href={pdfHorairesIndividuel.url}
                >
                  Télécharger la fiche tarif (PDF)
                </BtnPrimary>
              }
              </TabPanel>
              <TabPanel>
                <div dangerouslySetInnerHTML={{ __html: tarifsFamille }} />
                { pdfHorairesFamille && 
                  <BtnPrimary
                  as="a"
                  target="_blank"
                  href={pdfHorairesFamille.url}
                >
                  Télécharger la fiche tarif (PDF)
                </BtnPrimary>
              }
              </TabPanel>
              <TabPanel>
                <div dangerouslySetInnerHTML={{ __html: tarifsGroupe }} />
                { pdfHorairesGroupe && 
                <BtnPrimary as="a" target="_blank" href={pdfHorairesGroupe.url}>
                  Télécharger la fiche tarif (PDF)
                </BtnPrimary>
                }
              </TabPanel>
              <TabPanel>
                <div dangerouslySetInnerHTML={{ __html: tarifsEtudiant }} />
                { pdfHorairesEtudiant && 
                <BtnPrimary
                  as="a"
                  target="_blank"
                  href={pdfHorairesEtudiant.url}
                >
                  Télécharger la fiche tarif (PDF)
                </BtnPrimary>
                }
              </TabPanel>
            </Tabs>
            <Spacer />
            <Text dangerouslySetInnerHTML={{ __html: tarifsContenu }} />
            <Spacer />
          </PageInner>
        </BgWrap>
        <Spacer />
      </PageWrapper>
    </Fragment>
  )
}

export default HorairesTarifsPage
